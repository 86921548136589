<template>
  <b-container fluid>
    <div class="auth-card auth-card-container">
      <img
        id="profile-img"
        src="@/assets/mnwd_logo.png"
        class="auth-card-img"
      />
      <template v-if="!successful">
        <h5>Register A New Account</h5>
        <form>
          <label>Email</label>
          <b-form-group :invalid-feedback="invalidEmailFeedback">
            <b-form-input
              id="nested-email"
              :state="emailState"
              v-model="email"
              @blur="emailBlur = true"
            ></b-form-input>
          </b-form-group>

          <label>Username</label>
          <b-form-group :invalid-feedback="invalidUsernameFeedback">
            <b-form-input
              id="nested-username"
              :state="usernameState"
              v-model="username"
              @blur="usernameBlur = true"
            ></b-form-input>
          </b-form-group>

          <label>Password</label>
          <b-form-group :invalid-feedback="invalidPasswordFeedback">
            <b-form-input
              id="nested-password"
              :state="passwordState"
              v-model="password"
              type="password"
              @blur="passwordBlur = true"
            ></b-form-input>
          </b-form-group>

          <label>Repeat password</label>
          <b-form-group :invalid-feedback="invalidRepeatPasswordFeedback">
            <b-form-input
              id="nested-repeat-password"
              :state="repeatPasswordState"
              v-model="repeatPassword"
              type="password"
              @blur="repeatPasswordBlur = true"
            ></b-form-input>
          </b-form-group>

          <b-button
            type="submit"
            :disabled="!registerState || loading"
            variant="primary"
            @click="handleRegister"
          >
            REGISTER
          </b-button>
        </form>
      </template>
      <template v-else>
        <h5>Please Verify Your Account</h5>
        <span
          >An account registration request has been sent to your email. <br />
          Click the link in the meassage to validate your email address and
          create your account.
        </span>
      </template>
    </div>
    <div class="auth-card auth-card-container">
      <template v-if="!successful">
        Have an account? <router-link to="/signIn">Sign In</router-link>
      </template>
      <template v-else>
        Questions? <br />
        Contact: <a href="mailto: info@mnwd.com">info@mnwd.com</a>
      </template>
    </div>
  </b-container>
</template>

<script>
import validator from "@/mixins/validator";
export default {
  name: "Register",
  mixins: [validator],
  data() {
    return {
      email: "",
      username: "",
      password: "",
      repeatPassword: "",
      loading: false,
      successful: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleRegister() {
      if (this.isValidRegister()) {
        this.loading = true;
        this.$store
          .dispatch("auth/register", {
            username: this.username,
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.successful = true;
          })
          .catch((e) => {
            this.notify("danger", "Registration failed", "" + e);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        console.error("Invalid register form.");
      }
    },
  },
};
</script>
